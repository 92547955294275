<template style="cursor: pointer;">
  <div style="cursor: pointer" @click="onOpenLanguageSelection" id="language-select">
    <h3
      v-if="selectedExtraLanguage=='BGR'"
      id="language-label"
      style="cursor: pointer"
      :class=" showDarkBrand ? 'dark-color' : 'green-color'"
    >BGR</h3>
    <h3
      v-else-if="selectedExtraLanguage=='ITA'"
      id="language-label"
      style="cursor: pointer"
      :class=" showDarkBrand ? 'dark-color' : 'green-color'"
    >ITA</h3>
    <h3
      v-else-if="selectedExtraLanguage=='FRA'"
      id="language-label"
      style="cursor: pointer"
      :class=" showDarkBrand ? 'dark-color' : 'green-color'"
    >FRA</h3>
    <h3
      v-else-if="selectedExtraLanguage=='DEU'"
      id="language-label"
      style="cursor: pointer"
      :class=" showDarkBrand ? 'dark-color' : 'green-color'"
    >DEU</h3>
    <h3
      v-else
      id="language-label"
      style="cursor: pointer"
      :class=" showDarkBrand ? 'dark-color' : 'green-color'"
    >{{ labels.language.languageLabel[language] }}</h3>
    <img
      style="cursor: pointer; border: 1px solid #003340;"
      class="flag-icon"
      v-if="selectedExtraLanguage=='BGR'"
      src="../assets/bgr_flag.png"
      alt
    />
    <img
      style="cursor: pointer; border: 1px solid #003340;"
      class="flag-icon"
      v-else-if="selectedExtraLanguage=='ITA'"
      src="../assets/ita_flag.png"
      alt
    />
    <img
      style="cursor: pointer; border: 1px solid #003340;"
      class="flag-icon"
      v-else-if="selectedExtraLanguage=='FRA'"
      src="../assets/fra_flag.png"
      alt
    />
    <img
      style="cursor: pointer; border: 1px solid #003340;"
      class="flag-icon"
      v-else-if="selectedExtraLanguage=='DEU'"
      src="../assets/deu_flag.png"
      alt
    />
    <img
      style="cursor: pointer;"
      class="flag-icon"
      v-else-if="language=='GRE'"
      src="../assets/greek_flag.png"
      alt
    />
    <img style="cursor: pointer;" class="flag-icon" v-else src="../assets/uk_flag.png" alt />
  </div>
</template>

<script>
export default {
  name: "LamguageSelect",
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },
    showDarkBrand() {
      this.$route.name;
      if (this.$route.name) {
        let routeName = this.$route.name;
        let darkProfileScreen = routeName.includes("profile") && this.$route.query && this.$route.query.brand == 'dark'
        let showDarkBrand = routeName.includes("services") || routeName.includes("menu") || routeName.includes("open-tables") || routeName.includes("open-rooms") || routeName.includes("store-order-history") || routeName.includes("cart") || routeName.includes("history") || routeName.includes("pin") || darkProfileScreen;
        return showDarkBrand;
      } else {
        return false;
      }
    }
  },
  methods: {
    onOpenLanguageSelection: function () {
      this.$store.dispatch("openLanguageModal");
    },
  },
};
</script>

<style scoped>
#language-select {
  height: 35px;
  background: white;
  border-radius: 16px;
  display: flex;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);

  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer !important;
}

.flag-icon {
  margin-top: 3px;
  width: 25px;
}

#language-label {
  margin: 0;
  margin-right: 6px;
}

.green-color {
  color: #198b4a;
}

.dark-color {
  color: #003340;
}

h3 {
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  cursor: pointer !important;
  /* -webkit-text-stroke: 0.2px black; */
}

@media screen and (max-width: 800px) {
  #language-select {
    height: 4vh;
    min-height: 27px;
    max-height: 35px;
    padding: 1rem 1rem 1rem 1rem;
  }

  .flag-icon {
    margin-top: 0px;
    width: 21px;
  }
  h3 {
    font-size: 13px;
  }
}
</style>