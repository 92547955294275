<template>
  <div id="container">
    <h4
      @click="goToEntrance"
      id="prompt"
      :class=" showDarkBrand ? 'dark-color' : 'green-color'"
    >{{ labels.entrance.prompt[language] }}</h4>
  </div>
</template>

<script>
import LogApi from "../api/log";
export default {
  name: "EnterPrompt",
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    showDarkBrand() {
      this.$route.name;
      if (this.$route.name) {
        let routeName = this.$route.name;
        let showDarkBrand = routeName.includes("services") || routeName.includes("menu") || routeName.includes("open-tables") || routeName.includes("open-rooms") || routeName.includes("store-order-history") || routeName.includes("cart") || routeName.includes("history") || routeName.includes("pin");
        return showDarkBrand;
      } else {
        return false;
      }
    }
  },
  methods: {
    goToEntrance: function () {
      LogApi.enterFluterClicked();
      this.$emit("enter-clicked");
    },
  },
};
</script>

<style scoped>
.green-color {
  color: #198b4a;
}

.dark-color {
  color: #003340;
}

#prompt {
  min-height: 35px;
  background: white;
  border-radius: 16px;
  display: flex;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);

  padding: 0.5rem 1rem 0.5rem 1rem;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  #prompt {
    height: 4vh;
    padding: 1rem 1rem 1rem 1rem;
    min-height: 27px;
    max-height: 35px;
    font-size: 13px;
  }
}
</style>